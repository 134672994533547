import React, { useEffect, useState } from 'react'
import Icon from 'components/elements/Icon'

import { Follow, FollowersResponseType } from 'services/apiTypes/user.types'

import Header from 'components/userPage/Header'
import Link from 'components/elements/Link'
import Modal from 'components/elements/Modal'
import FollowedUsers from 'components/accountSettingsPage/FollowedUsers'
import AvatarCircle from 'components/user/AvatarCircle'
import FadeInOut from 'components/transitions/FadeInOut'

import { encodeUsernameRoute } from 'utils/encoding'

import styles from './userList.module.scss'

type Props = {
  header: string
  fetchUsers: (next?: string) => Promise<FollowersResponseType>
  className?: string
}

export const defaultFollowerResponse: FollowersResponseType = {
  count: 0,
  next: '',
  previous: '',
  results: new Array(15).fill(undefined),
}

const UserList = ({ header, fetchUsers, className = '' }: Props) => {
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState<FollowersResponseType>(defaultFollowerResponse)

  useEffect(() => {
    setLoading(true)

    fetchUsers()
      .then(setUsers)
      .catch(err => {
        console.error(err)

        setUsers({ count: 0, next: '', previous: '', results: [] })
      })
      .finally(() => setLoading(false))
  }, [])

  const Secondary = (
    <Modal
      closeButton
      trigger={
        <>
          View {header.toLocaleLowerCase()} <Icon name="arrow right" />
        </>
      }>
      <FollowedUsers header={header} fetchUsers={fetchUsers} />
    </Modal>
  )

  return (
    <div className={className}>
      <Header main={`${header} (${users?.count})`} secondary={Secondary} />
      <div className={styles.users}>
        {users?.results.map((user, index) => (
          <ListItem key={index} user={user} loading={loading} />
        ))}
      </div>
      {!loading && !users?.results.length && <div className={styles.noFollowers}>No {header.toLocaleLowerCase()}</div>}
    </div>
  )
}

export default UserList

const ListItem = ({ user, loading }: { user: Follow; loading: boolean }) => (
  <Link href={loading ? '/' : `/u/${encodeUsernameRoute(user?.username)}`} className={styles.link}>
    <span>
      <span className={`${styles.imageContainer} ${loading ? styles.loading : ''}`}>
        <FadeInOut show={!loading}>
          <AvatarCircle src={user?.avatar} username={user?.username} />
        </FadeInOut>
      </span>
      <FadeInOut show={!loading}>
        <span className={styles.username}>{user?.username}</span>
      </FadeInOut>
    </span>
  </Link>
)
