import { PatchFolderItemType, SelectedFolderItemType } from 'types/folders'

import { RequestService } from 'services/request.service'

export class FolderService extends RequestService {
  public details = (id?: number | string, query?: string) => {
    const rootFolderId = typeof window !== 'undefined' && !id ? location.pathname.split('/folders/')[1] : id
    const params = typeof window !== 'undefined' && !query ? location.search : `?${query}`

    return super.get(`/api/decks/folders/${rootFolderId}/${params}`)
  }

  public deleteItems = (items: SelectedFolderItemType[]) => {
    return super.post('/api/decks/folders/deleteItems/', { items })
  }

  public updateItems = (items: PatchFolderItemType[]) => {
    return super.patch('/api/massUpdate/', { items })
  }

  public create = (name: string, isPrivate: boolean, parentFolderId: string | number) => {
    return super.post('/api/decks/folders/', {
      name,
      private: isPrivate,
      parentFolder: parentFolderId,
    })
  }
}

const folderService = new FolderService()

export default folderService
