import React, { useEffect, useState } from 'react'

import { Follow, FollowersResponseType } from 'services/apiTypes/user.types'

import AvatarCircle from 'components/user/AvatarCircle'
import FollowButton from 'components/user/FollowButton'
import Link from 'components/elements/Link'

import { encodeUsernameRoute } from 'utils/encoding'

import styles from './followedUsers.module.scss'

type Props = {
  header: string
  fetchUsers: (next?: string) => Promise<FollowersResponseType>
}

const FollowedUsers = ({ header, fetchUsers }: Props) => {
  const [_loading, setLoading] = useState(true)
  const [users, setUsers] = useState(new Array<Follow>())
  const [nextPage, setNextPage] = useState<string | null>(null)
  const [count, setCount] = useState(0)

  useEffect(() => {
    fetchUsers()
      .then(data => {
        if (!data.results) return

        setUsers([...users, ...data.results])
        setNextPage(data.next)
        setCount(data.count)
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
  }, [])

  const handleFetchNext = () => {
    if (!nextPage) return

    fetchUsers(nextPage || undefined)
      .then(data => {
        if (!data.results) return

        setUsers([...users, ...data.results])
        setNextPage(data.next)
        setCount(data.count)
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
  }

  return (
    <div className={styles.container}>
      <i>
        {header} ({count})
      </i>
      <div className={styles.users}>
        {users.map((user, index) => (
          <div key={index} className={styles.row}>
            <Link href={`/u/${encodeUsernameRoute(user.username)}`}>
              <AvatarCircle src={user?.avatar} username={user?.username} />
              <div>{user.username}</div>
            </Link>
            <FollowButton followId={user.id} followed={user.following} className={styles.followButton} />
          </div>
        ))}
      </div>

      {nextPage && (
        <div className={styles.loadMore}>
          <button onClick={handleFetchNext}>Load more</button>
        </div>
      )}
    </div>
  )
}

export default FollowedUsers
